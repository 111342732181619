.trusted__list {
	display: flex;
  justify-content: start;
  flex-wrap:wrap;
}

.trusted__item {
  width: 20%;
}

@media only screen and (min-width: 769px) and (max-width: 1439px){
  .trusted__item {
    width: 25%;
    margin-bottom:20px;
  }

  .trusted__item img {
  	width: 100%;
  }
}

@media only screen and (max-width: 457px) {
  .trusted__item {
    width: 100%;
    margin-bottom: 10px;
  }
}