@media (min-width: 1600px ) and (max-width: 1919px) {
	.page {
  	max-width: 100%;
	}

	.booking__right {
		display: flex;
    justify-content: flex-end;
	}

	.sliderimg {
    width: 100% !important;
	}

	.homepage__slider img {
		width:100%;
	}

	.about, .managers, .city, .services-page, .container, .homepage__services {
		width: 1200px;
    margin-right: auto;
  	margin-left: auto;
	}

	.booking:not(button) {
    justify-content: center;
    background: linear-gradient(156deg, #38689D 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
	}

	.booking__left, .booking__right {
		width:600px;
	}

}