.post__content {
	display: flex;
    justify-content: space-between;
}

.post__left {
	max-width:660px;
}

.post__right {
	max-width:350px;
}

.post__image {
	border-radius: 10px;
	margin-bottom:40px;
}

.post__image img {
	width: 350px;
}

.blog__item img {
	width: 350px;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
}

.rdw-editor-main {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  height: 300px;
}

@media only screen and (max-width: 768px){
	.blog__item img {
	    width: 100%;
	}
}