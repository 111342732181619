select {
  width:100%;
}

select option:selected {
  color:yellow;
}

select.unselected {
  opacity: .5;
}

.service__info {
	display: flex;
	justify-content: space-between;
}

.service__name {
	max-width:540px;
}

.service__name p {
	font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #7A7A7A;
}

.service__form {
	max-width:440px
}

.service__form img {
  width:100%;
}

.service__info {
	margin-bottom:80px;
}

.service__testimonials {
	margin-bottom:80px;
}

.subservices__list {
  min-width: 254px;
  position: fixed;
  top: 50px;
  left: 40%;
  z-index: 6;
  background: #fff;
  padding: 16px;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #7A7A7A;
  border-radius: 10px;
  text-align: left;
}

.subservices__list:before {
  content: " ";
  height: 10px;
  background: linear-gradient(270.25deg, #FFB516 -0.51%, rgba(255, 181, 22, 0.26) 151.26%);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.subservices__item:not(:last-child) {
  border-bottom: 1px solid #D3D3D3;
}

@media only screen and (max-width: 768px) {
  .service__info {
      flex-wrap: wrap;
  }

  .subservices__list {
    left: 50%;
    transform: translate(-50%, 0);
  }
}