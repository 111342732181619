
@media only screen and (min-width: 769px) and (max-width: 1439px){
  .homepage__trusted {
    padding: 0px 83px;
  }
}

@media only screen and (max-width: 768px) {
  .alice-carousel {
    max-height: 477px;
    overflow: hidden;
  }

  p {
    text-align: justify;
  }

  .social {
    text-align: center;
  }

  .header .booking {
    display: none;
  }

  .header__number {
    display: none;
  }

  .about-info__text h3 {
    margin-bottom: 15px;
  }

  .services__item p {
    text-align: unset;
  }

  h1 {
    font-size: 38px;
    line-height: 130%;
    margin:10px 0px;
  }

  .post h1 {
    font-size: 22px;
  }

  .btn {
    width:100%;
  }

  .btn-phone {
    font-size: 0;
    width: 40px;
    height: 40px;
    padding: 0px;
  }

  .btn-phone:before {
    bottom: 8px;
    left: 9px;
  }

  .btn-yellow {
    padding: 16px 40px 16px 40px;
  }

  .homepage__slider {
    overflow: visible;
    margin-bottom: 80px;
    max-height: 477px;
  }

  .page {
    max-width: 100%;
    overflow: hidden;
  }


  .dropdown {
    width: 100%;
  }

  .mainmenu {
    display: none;
  }

  .mobilemenu > .mainmenu {
    display: block;
  }

  .mainmenu_desktop {
    display: none;
  }

  .mainmenu {
    margin-bottom: 90px;
  }

  .mainmenu__list {
    padding-left: 0px;
  }

  .mainmenu__list > li {
    display: block;
    margin: 20px 0px;
    text-align: center;
  }

  .mobilemenu {
    /*display: none;
    height: 0px;*/
    transition: 0.3s;
  }

  .mobilemenu-button {
    position: relative;
    width: 20px;
  }

  .mobilemenu-button > span,
  .mobilemenu-button > span::before,
  .mobilemenu-button > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #333333;
  }

  .mobilemenu-button > span::before {
    content: '';
    top: -6px;
  }

  .mobilemenu-button > span::after {
    content: '';
    top: 6px;
  }

  .slide {
    background-size: cover;
    padding: 48px 16px;
  }

  .container {
    padding:0px 16px;
  }

  .full-width {
    margin:0px;
  }

  .slider__info {
    top: 48px;
    left: 16px;
    max-width: 100%;
    padding-right: 16px;
  }

  .call-us {
    position: initial;
  }

  .booking-form {
    padding: 48px 16px;
  }

  .booking2 {
    flex-wrap: wrap;
  }

  /* header */

  .advantages-list__item {
    margin-bottom: 20px;
    width: 100%;
  }

  .homepage__trusted {
    margin-bottom:80px;
  }

  .homepage__blog {
    padding: 0 16px;
    margin-bottom:80px;
  }

  .homepage__services {
    padding: 0 16px;
  }

  .homepage__cities {
    margin-bottom:80px;
  }

  .homepage__testimonials {
     padding: 0 16px;
    margin-bottom:80px;
  }

  .services__list {
    flex-wrap:wrap;
  }

  .services__item {
    width: 156px;
    margin-right: 0px;
    margin-bottom: 16px;
    height: 156px;
    padding: 38px 16px 16px 16px;
    box-sizing: border-box;
  }

  .services__item img {
    width: 48px;
  }

  .services__item  p {
    margin-top: 11px;
    font-size: 16px;
  }

  .services {
    margin-bottom: 90px;
  }

  .service__form {
    width: 100%;
  }

  .trusted__list {
    flex-wrap: wrap;
  }

  .trusted__item {
    width:100%;
  }

  .trusted__item img {
    width:100%;
  }

  .cities-list {
    justify-content: space-between;
  }

  .post__content {
    flex-wrap: wrap;
  }

  .post__right {
    width: 100%;
  }

  .blog__list {
    margin-bottom: 20px;
  }

  .blog__item .read-more {
    float: none;
  }

  .blog__item img {
    width: 100%;
  }

  .state-list {
    width: 156px;
    margin-right: 0px;
  }

  .booking {
    flex-wrap: wrap;
  }

  .booking__left {
    box-sizing: border-box;
    padding: 48px 16px;
    max-width: 100%;
    width: 100%;
  }

  .booking__right {
    max-width: 100%;
    width: 100%;
  }

  .booking__right img {
    width: 100%;
  }

  .booking-form_popup {
    right: 16px;
    width: 300px;
    position:absolute;
  }

  .booking-form form {
    width: auto;
  }

  .booking-form .btn {
    width: 100%;
  }

  .booking2__left {
    margin: auto;
    margin-bottom: 60px;
  }

  .booking2__right .booking-form {
    box-shadow: none;
  }

  .booking2__right img {
    width: 100%;
  }

  .booking2__right {
    width:100%;
    padding: 0px;
  }

  .booking2__left {
    padding-left: 0px; 
  }

  .advantages-list {
    padding: 16px 48px;
    flex-wrap: wrap;
  }

  .request__form {
    width: 100%;
  }

  .request-form_popup {
    right: 16px;
    width:300px;
  }

  .request__form {
    padding: 24px 24px;
  }

  .request__form input {
    width: 100%;
  }

  .request__form textarea {
    width: 100%;
  }

  .blog__item {
    max-width: 100%;
  }


  .about-info__block {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .about-info__block img {
    width:100%;
  }

  .managers__trusted {
    margin-bottom:80px;
  }

  .managers-info {
    flex-wrap: wrap;
  }

  .managers-info__text {
    margin-bottom: 40px;
  }

  .managers-info__text .managers__request {
    max-width: 100%;
    width: auto;
  }

  .managers-info__right img {
    width:100%;
  }

  .request {
    flex-wrap:wrap;
  }

  .request__image img {
    height: 100%;
    width: 100%;
  }

  .request__image {
    width: 100%;
    border-radius: 5px;
  }

  /* footer */
  .footer {
    padding: 48px 16px;
  }

  .footer-left {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .footer-right {
    width: 100%;
  }

  .footer__contact form {
    margin-bottom:20px;
  }

  .footer input[type="text"] {
    width: 100%;
    margin-right: 0px;
    padding-right: 0px;
  }

  .footer__main {
    flex-wrap: wrap;
    text-align: center;
  }

  .footer input[type="email"] {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 457px) {
  .advantages-list {
    padding: 30px 20px 40px;
  }

  .advantages-list__item {
    margin-right:0px;
  }
}