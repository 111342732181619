.city__services {
  margin: 40px 0px 50px 0px;
}

.city-info {
	display: flex;
	justify-content: space-between;
}

.city-info__booking {
	width:50%;
}


.city-info__wrap {
  width:50%;
  padding-left: 100px;
}
.city-info__text {
	padding:50px 0px;
  background-size: cover;
}

.city-info__data {
	background:#fff;
  width: max-content;
  padding: 30px 40px 40px 30px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.city-info__text {
  max-width: 540px;
  border-radius: 10px;
}

.city-info__booking {
  max-width: 380px;
}


.city__booking-button {
  margin-top: 35px;
}

.city-info__booking p{
	font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #7A7A7A;
}

.city-info__data {
  position: relative;
  left: -95px;
}

.city__info {
  margin-bottom: 75px;
}

.city__booking {
	margin-bottom:90px;
}

.city__contact div {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #36669B;
  margin-bottom:15px;
}

.city__contact a {
  text-decoration: none;
  color: #36669B;
}

.city__name {
  margin-bottom:90px;
}

.city__contact div img {
  margin-right:15px;
}

.city__reviews {
  margin-bottom:90px;
}

@media only screen and (max-width:768px) {
  .city-info {
      flex-wrap: wrap;
  }

  .city-info__booking {
      width: 100%;
      margin-bottom: 60px;
  }

  .city-info__text {
      width: 100%;
      }
  .city-info__data {
      left: 16px;
  }
}