.admin {
  .lds-dual-ring {
      padding-top: 0px;
  }
}

.admin {
	margin-left:100px;
}

.admin__title {
  display: flex;
  align-items: center;
}

.admin__tab {
	border-bottom: 1px solid grey;
}

.admin__item {
	display: flex;
}

.admin__item ul {
	list-style-type: none;
  padding-left: 0px;
}

.admin__left {
	width: 50%;
  padding-right: 20px;
}

.admin__right {
  width: 50%;
}

.admin__name {
	width:300px;
	display: inline-block;
}

.admin input, .admin textarea, .admin select {
  display: block;
  color: #000119;
  width: 300px;
  margin-bottom:24px;
}

.admin textarea {
  padding: 10px 10px 10px 16px;
  height: 200px;
}

.admin input[type="time"] {
  margin-bottom: 20px;
	display: block;
  box-sizing: border-box;
  font-size: 14px;
  color: #7A7A7A;
  font-family: Montserrat;
  padding: 5px 10px;
  background: #FFFFFF;
  border: none;
  height: 40px;
  border: 1.5px solid rgba(54, 102, 155, 0.37);
  padding-left: 16px;
  border-radius: 0px;
}

.admin .btn {
	border-radius: 0px;
	margin: 10px 10px;
  margin-left:0px;
}

.admin .upload-button {
    margin-left:0px;
}

.admin {

    input[type="text"], input[type="password"] {
    	border-radius: 0px;
    }

}


.admin textarea {
	border-radius: 0px;
}


.admin select {

  margin-bottom: 20px;

    font-size: 14px;
    color: #7A7A7A;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    padding-left: 16px;
    background: #FFFFFF;
    border-radius: 0px;
    border: none;
    height: 40px;
    border: 1.5px solid rgba(54, 102, 155, 0.37);

    color: rgba(54, 102, 155, 0.37);


}

.admin .select_multiple {
        color: #7A7A7A;
}

.admin .select_multiple {
  height: 200px;
  width: 100%;
}


.admin .tab-list {
list-style: none;
padding-left: 0px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    display: flex;
}

.admin .tab-list-item {
	display: inline;
	border: 1px solid lightgray;
    padding: 5px 20px;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom:5px;
}

.admin .tab-list-active {
	    background: lightgrey;
}


.admin-cities__form > div {
        width: 400px;
}

.admin-cities__form {
    input[type="text"], select, input[type="time"] {
        width:100%;
    }
}

.admin {
    .city__item, .post__item, .service__item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px lightgrey solid;
        margin-bottom: 20px;
        padding: 10px 20px;
    }
}


.admin .service__item {
    flex-wrap:wrap;
}

.admin .city__data {
        margin-right: 50px;
}


.admin__slides {
    display: flex;
}

.admin__slide {
        border: 1px solid lightgrey;
    padding: 5px;
    height:100px;
    width:200px;
    margin-right:20px;
}

.admin__slide img {
        object-fit: cover;
            height: 75px;
    width: 100%;

}

.slide__delete {
    cursor:pointer;
}


.admin__item {
                border-bottom: 1px solid lightgrey;
}


 .admin__item .admin__about{
    margin-bottom: 20px;
    padding-bottom: 30px;
    width: 50%;
}



/* mobile */


@media (max-width: 768px) {

    .admin {
            margin: 0px 20px 0px 20px !important;
    }

    .admin .tab-list-item {
        display: block;
            margin-bottom: -1px;
                width: 20%;
    }

    .admin__item {
        flex-wrap: wrap;
    }

    .admin__left, .admin__right {
        width: 100%;
    }

    .admin .city__item, .admin .post__item, .admin .service__item { 
        flex-wrap: wrap;
    }

   .admin input, .admin textarea {
        width: 100%;
    }
    .admin-cities__form input[type="text"], .admin-cities__form select, .admin-cities__form input[type="time"] {
        width: auto;
    }

    .admin .select_multiple {
    
        width: auto;
    }

    .order__item, .message__item {
        width: auto;
    }

    .admin__slides {
        flex-wrap: wrap;
    }

    .admin__slide {
        margin-bottom:20px;
        width: 100%;
    }

     .admin__item .admin__about{
         width: 100%;
    }

     .admin__item .admin__home{
         width: 100%;
    }



.edit-buttons {
    padding-left: 0px;
    margin-top:20px;
    flex-direction: row;
}

}