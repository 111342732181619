.subscribe_yellow {
	background: #FFFFFF;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	padding:42px 55px 42px 47px;
}

.subscribe_yellow h3 {
	margin-bottom:30px;
}

.subscribe_yellow .btn {
	width: 100%;
}

.subscribe_yellow input {
	width: -webkit-fill-available;
}

