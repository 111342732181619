$grey: #e0d5d5;
body {
  background: $grey;
}

$grey: #e0d5d5;
body {
  background: $grey;
}

html {
  scroll-behavior: smooth;
}

option:first-child {}

select option[value="0"] {
  ;
}

button[disabled] {
  background: linear-gradient(284.89deg, #38689d8a 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
}

.edit-buttons {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.about p,
.managers p {
  white-space: pre-wrap;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000059;
  z-index: 5;
  position: fixed;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: #e0d5d5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page {
  max-width: 1440px;
  margin: auto;
  background: #FFF;
}

.container {
  padding: 0px 165px;
}

.full-width {
  margin-left: -165px;
  margin-right: -165px;
}

p {
  line-height: 150%;
  letter-spacing: 0.02em;
}

h1 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 16px;
  color: #000;
}

input:focus {
  outline: none;
}

.btn {
  cursor: pointer;
  font-family: Montserrat;
  text-decoration: none;
}

.btn:focus {
  outline: none;
}

.btn-yellow {
  position: relative;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFF;
  background: #FFB516;
  box-shadow: 0px 4px 35px RGB(255 181 22 / 60%);
  border-radius: 65px;
  border: none;
  padding: 16px 70px 16px 54px;
  transition: 0.3s;
}

.btn-yellow:hover {
  color: #36669B;
  transition: 0.3s;
}

.btn-yellow::after {
  content: " ";
  background: url('images/arrow-right.png');
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  bottom: 14px;
  right: 20px;
  transition: 0.3s;
}

.btn-yellow:hover::after {
  background: url('images/arrow-right-blue.png');
  transition: 0.3s;
  animation-name: arrow;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* animation-direction: alternate-reverse;*/
}

.btn-blue {
  position: relative;
  padding: 10px 24px;
  background: linear-gradient(284.89deg, #38689D 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
  box-shadow: 0px 0px 29px RGB(54 102 155 / 50%);
  border-radius: 41px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #fff;
  text-transform: uppercase;
}

.btn-phone {
  padding-left: 50px;
}

.btn-phone:hover {
  color: #FFB516;
}

.btn-phone:before {
  content: " ";
  background: url('images/callus.png');
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  bottom: 6px;
  left: 15px;
}

.btn-phone:hover::before {
  background: url('images/callus-yellow.png');
  background-repeat: no-repeat;
  background-position: center;
  animation-name: phone;
  animation-duration: 0.7s;
}

.btn-white {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #36669B;
  border: 1.5px solid rgba(54, 102, 155, 0.37);
  box-sizing: border-box;
  border-radius: 41px;
  padding: 10px 56px 10px 32px;
  background: #FFF;
}

.btn-white::after {
  content: " ";
  background: url('images/arrow-right-blue.png');
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  bottom: 9px;
  right: 13px;
}

.btn-blank {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #36669B;
  text-decoration: none;
  padding-right: 37px;
}

.btn-blank::after {
  content: " ";
  background: url('images/arrow-right-blue.png');
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.phones {
  position: fixed;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 0px 0px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 4px 35px RGB(0 0 0 / 15%);
  transition: all .25s ease;
  z-index: 6;
}

.dropdown {
  /*display: none;*/
  visibility: hidden;
  opacity: 0;
  width: 300px;
  padding: 0px 0px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.15);
  transition: all .25s ease;
  z-index: 4;
}

.dropdown__title {
  border-radius: 10px 10px 0px 0px;
  padding: 16px 0px;
  padding-left: 16px;
  color: #fff;
  background: linear-gradient(284.89deg, #38689D 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
}

.dropdown__title h3 {
  margin: 0px;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  /* 5 */
  color: #FFFFFF;
}

.dropdown__list {
  padding: 0px 16px;
}

.dropdown__sublist {
  display: none;
}

.dropdown-trigger:hover>.dropdown {
  display: block;
  visibility: visible;
  opacity: 1;
}

.dropdown__item:hover>.dropdown__sublist {
  display: block;
}

.dropdown {
  /*top: 45px;*/
  position: absolute;
  right: 0;
}

.dropdown__list {
  list-style-type: none;
  background: #FFF;
}

.dropdown__item>p {
  margin: 0px;
}

.dropdown__item {
  padding: 14px 0px 14px 0px;
  position: relative;
  font-size: 16px;
  color: #7A7A7A;
  border-bottom: 1px solid #D3D3D3;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  letter-spacing: 0.02em;
}

.dropdown__item:last-child {
  border-bottom: 0px;
}

.dropdown__item:hover {
  color: #1D1D1D;
  font-weight: bold;
}

.dropdown__item:hover::before {
  content: " ";
  position: absolute;
  width: 4px;
  height: 24px;
  left: -16px;
  background: #FFB516;
  border-radius: 0px 5px 5px 0px;
}

.dropdown__sublist {
  margin-top: 10px;
  padding-left: 24px;
  list-style-type: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #7A7A7A;
}

.dropdown__subitem {
  margin-bottom: 10px;
}

.dropdown__subitem a .city-name:hover {
  font-weight: bold;
}

.dropdown__subitem a:hover {
  font-weight: bold;
}

.dropdown__subitem a .city-name {
  color: #7A7A7A;
}

.dropdown__subitem a {
  text-decoration: none;
  color: #36669b;
}

.contact-popup {
  z-index: 5;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(54, 102, 155, 0.2);
  border-radius: 100%;
  border: 10px solid rgba(54, 102, 155, 0.15);
  box-shadow: 0 0 0px 10px rgba(54, 102, 155, 0.1);
  padding: 10px;
  display: flex;
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

.call-us {
  position: relative;
}

.header {
  padding: 40px 50px;
  box-shadow: 0px 4px 25px RGB(0 0 0 / 15%);
  margin-bottom: 50px;
}

.header .btn-yellow {
  font-size: 14px !important;
  padding: 12px 49px 12px 24px;
  margin-bottom: 10px;
  margin-bottom: 15px;
}

.header .btn-yellow::after {
  height: 19px;
}

.header__phone a {
  display: flex;
  align-items: center;
}

.header__phone a {
  color: #36669b;
  text-decoration: none;
  font-size: 18px;
  margin-left: 5px;
}

.top-logo a {
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #1D1D1D;
  text-decoration: none;
}

.content {
  margin-bottom: 100px;
}

.homepage__slider {
  position: relative;
  margin-bottom: 110px;
  color: #FFF;
  max-height: 552px;
  overflow: hidden;
}

.homepage__slider p {
  margin-bottom: 40px;
}

.slide {
  background-size: cover;
  padding: 120px 165px;
  padding-right: 600px;
}

.slider__info {
  position: absolute;
  top: 0;
  z-index: 3;
  max-width: 620px;
  top: 112px;
  left: 165px;
}

.sliderimg {
  /* width: auto !important; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialItem {
  margin-right: 5px;
}

.mobilemenu {
  /* display: none;*/
}

.mainmenu__list {
  list-style: none;
  margin-bottom: 15px;
  /* margin-inline-start: 0px; */
  padding-inline-start: 0px;
}

.mainmenu__list>li {
  display: inline;
  margin-right: 30px;
  font-size: 16px;
}

.mainmenu__list>li a {
  color: #7A7A7A;
  font-weight: 500;
  text-decoration: none;
}

.mainmenu__list>li a.active {
  color: #1D1D1D;
  font-weight: bold;
  border-bottom: 3px solid #FFB516;
}

.mainmenu__item {
  position: relative;
}

.mainmenu__sublist {
  max-width: 254px;
  left: 0;
}

.cities-list {
  display: flex;
  flex-wrap: wrap;
}

.state-list {
  margin-right: 115px;
  width: 160px;
  border-top: 1.5px solid rgba(54, 102, 155, 0.37);
}

.state-list:not(last-child) {}

.state-list h3 {
  font-size: 16px;
}

.state-list ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
}

.state-list ul li a {
  color: #7A7A7A;
  text-decoration: none;
  line-height: 150%;
}

.homepage__booking {
  margin-bottom: 110px;
}

.booking {
  display: flex;
  color: white;
  overflow: hidden;
}

.booking__left {
  width: 50%;
  background: linear-gradient(284.89deg, #38689D 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
  padding: 76px 70px 88px 165px;
}

.booking__right {
  width: 50%;
  background: linear-gradient( 112deg, #38689d 40.15%, rgba(54, 102, 155, .42) 178.17%);
}

.booking__right img {
  height: 100%;
}

.booking-form_popup {
  z-index: 6;
  background: #FFF;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50px;
}

.booking2 button {
  margin-top: 20px;
}

.booking2 {
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.booking2 p {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #7A7A7A;
}

.booking2__left {
  padding-left: 20px;
}

.booking2__right {
  max-width: 440px;
  border-radius: 10px;
  padding: 35px;
}

.service__info .service__form {
  max-width: 540px;
}

.homepage {
  margin-top: -50px;
}

.homepage__cities {
  padding: 0 165px;
  margin-bottom: 110px;
}

.homepage__advantages {
  margin-bottom: 110px;
}

.advantages-list {}

.advantages-list {
  padding: 48px 165px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(284.89deg, #38689D 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
}

.advantages-list__item {
  text-align: center;
  margin-right: 30px;
  color: white;
  width: 33%;
}

.advantages-list__item h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #FFB516;
  text-shadow: 0px 4px 35px rgba(255, 181, 22, 0.6);
}

.homepage__services {
  padding: 0 165px;
}

.services {
  margin-bottom: 110px;
}

.services__list {
  display: flex;
  justify-content: space-between;
}

.services__item {
  position: relative;
  margin-right: 32px;
  width: 253px;
  text-align: center;
  box-shadow: 0px 4px 4px RGB(0 0 0 / 25%);
  background: #FFF;
  padding: 43px 16px 16px 16px;
  color: #36669B;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}

.services__item a {
  text-decoration: none;
  display: block;
  color: #36669B;
}

.services__item:last-child {
  margin-right: 0px;
}

.services__item:hover {
  /*transform: translateY(-5px);
  transition: 0.3s;*/
}

.services__item:before {
  content: " ";
  height: 10px;
  background: linear-gradient(270.25deg, #FFB516 -0.51%, rgba(255, 181, 22, 0.26) 151.26%);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage__testimonials {
  margin-bottom: 110px;
  padding: 0 165px;
}

.homepage__trusted {
  margin-bottom: 110px;
  padding: 0 165px;
}

.homepage__featured {
  margin-bottom: 110px;
  padding: 0 165px;
}

.homepage__blog {
  margin-bottom: 110px;
  padding: 0 165px;
}

.services-page__services {
  max-width: 542px;
  margin: auto;
}

.services-page__services .services__list {
  flex-wrap: wrap;
}


/* .services-page__services .services__list */

.services__item {
  width: 224px;
  margin-bottom: 32px;
  margin-right: 0px;
}

.blog__list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
  flex-wrap: wrap;
}

.blog__item {
  max-width: 350px;
  margin-bottom: 50px;
}

.blog__item h3 {
  font-size: 16px;
  line-height: 150%;
}

.blog__item p {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #7A7A7A;
}

.blog__item .read-more {
  float: right;
}

.more-articles {
  margin: auto;
  display: block;
}


/* FOOTER */

.footer {
  background: #F3F3F3;
  padding: 65px 175px 65px 165px;
}

.footer h3 {
  font-size: 16px;
  margin-bottom: 24px;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer ul li {
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #7A7A7A;
}

.footer ul li a {
  color: #7A7A7A;
  text-decoration: none;
}

.footer__main {
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
}

.footer-left {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.footer-left ul li {
  margin-bottom: 10px;
}

.footer__down {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #7a7a7a;
  display: flex;
  justify-content: space-between;
  border-top: 1.5px solid #D3D3D3;
  padding-top: 15px;
}

.footer__contact a {
  text-decoration: none;
  color: #36669B;
  font-weight: 600;
}

.footer .subscribe {
  background: #f3f3f3;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.footer .subscribe .btn {
  position: relative;
  padding: 10px 24px;
  background: linear-gradient( 284.89deg, #38689D 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
  box-shadow: 0px 0px 29px RGB(54 102 155 / 50%);
  border-radius: 41px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #fff;
  text-transform: uppercase;
  width: auto;
}


/* ANIMATIONS */

@keyframes pulse {
  from {
    padding: 10px;
    bottom: 20px;
    right: 20px;
  }
  to {
    padding: 0px;
    bottom: 30px;
    right: 30px;
  }
}

@keyframes arrow {
  0% {
    right: 20px;
    /* 10 20 */
  }
  33% {
    right: 10px;
  }
  66% {
    right: 25px;
  }
  100% {
    right: 20px;
  }
}

@keyframes phone {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

.booking-form {
  padding: 42px 76px 46px 66px;
  box-shadow: 0px 4px 35px RGB(0 0 0 / 15%);
  border-radius: 10px;
  text-align: center;
}

.booking-form form {
  width: 300px;
}

.booking-form .btn {
  width: 300px;
}

.booking-form input[type="text"],
.booking-form input[type="email"] {
  width: 100%;
  display: block;
}

.footer input[type="text"],
.footer input[type="email"] {
  border: none;
  width: 285px;
  margin-right: -70px;
  padding-right: 80px;
  box-sizing: border-box;
}

input::placeholder,
textarea::placeholder {
  color: rgba(54, 102, 155, 0.37);
}

textarea {
  box-sizing: border-box;
  font-size: 14px;
  color: #7A7A7A;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  background: #FFFFFF;
  border-radius: 40px;
  border: none;
  height: 40px;
  border: 1.5px solid rgba(54, 102, 155, 0.37);
}

input[type="textarea"] {
  font-size: 14px;
  color: #7A7A7A;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  background: #FFFFFF;
  border-radius: 40px;
  border: none;
  height: 40px;
  border: 1.5px solid rgba(54, 102, 155, 0.37);
  box-sizing: border-box;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="time"],
select {
  box-sizing: border-box;
  margin-bottom: 24px;
  font-size: 14px;
  color: #7A7A7A;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  padding-left: 16px;
  background: #FFFFFF;
  border-radius: 40px;
  border: none;
  height: 40px;
  border: 1.5px solid rgba(54, 102, 155, 0.37);
}

.datetime {
  display: flex;
  justify-content: space-between;
}

input[type=date]::-webkit-calendar-picker-indicator,
input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(70%);
}

input[type="date"]::-webkit-date-edit-text {
  color: red;
}

.datetime input[type="date"],
.datetime input[type="time"] {
  width: 48%;
  display: inline;
}

.datetime input[type="date"]:placeholder {
  color: red;
}

input[type="text"]:focus {
  outline: none;
}

input[type="textarea"]:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.file-upload::-webkit-file-upload-button {
  visibility: hidden;
}

.file-upload::before {
  content: "Choose file";
  position: absolute;
  padding: 5px 8px;
  background: linear-gradient( 284.89deg, #38689D 40.15%, rgba(54, 102, 155, 0.42) 178.17%);
  box-shadow: 0px 0px 29px RGB(54 102 155 / 50%);
  /* border-radius: 41px; */
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  height: 17px;
  display: block;
  width: 88px;
}

.signin form {
  margin-bottom: 20px;
}

.signin input {
  margin-right: 10px;
}

.passforget input {
  margin-right: 10px;
}

.request {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.request__image {
  width: 60%;
  overflow: hidden;
}

.request__image img {
  height: 100%;
}

.request__form {
  width: 40%;
  padding: 42px 75px 34px 67px;
}

.request__form input {
  width: 300px;
  display: block;
  margin-bottom: 18px;
}

.request__form input[type="textarea"] {
  border-radius: 20px;
}

.request__form textarea {
  border-radius: 20px;
  padding-left: 16px;
  padding-top: 5px;
  width: 300px;
  height: 100px;
  margin-bottom: 15px;
}

.managers__info {
  margin-bottom: 60px;
}

.managers__trusted {
  margin-bottom: 110px;
}

.managers-info__text {
  max-width: 540px;
}

.managers-info__text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #7A7A7A;
  margin-bottom: 25px;
}

.managers-info {
  display: flex;
  justify-content: space-between;
}

.managers__request {
  display: inline-block;
  margin-top: 35px;
}

.managers-info__text h1 {
  margin-top: 0px;
}

.about-info__image {
  max-width: 450px;
}

.about-info__image img {
  max-width: 450px;
}

.about-info__block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

.about-info__text {
  max-width: 352px
}

.about-info__text h3 {
  margin-bottom: 35px;
}

.about-info__text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #7A7A7A;
}

.breadcrumbs__list {
  padding-left: 0px;
  list-style-type: none;
}

.breadcrumbs__item {
  display: inline;
  line-height: 150%;
}

.breadcrumbs__item a {
  color: #FFB516;
  text-decoration: none;
}

.breadcrumbs__item:not(:last-child):after {
  content: " > ";
  color: #000000;
}


/* preloader */

.lds-dual-ring {
  padding-top: 25px;
  margin: auto;
  display: block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #36669a;
  border-color: #36669a transparent #36669a transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.request-form_popup {
  box-sizing: border-box;
  z-index: 6;
  background: #FFF;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50px;
  border-radius: 10px;
  width: auto;
}

.passforget__form {
  margin-bottom: 40px;
}